.top-bar-wrapper {
    --primary-color: #454545;
    --text-color: #333;
    --background-color: #ffffff;
    --hover-color: #f8f9fa;
    --border-color: #e0e0e0;
    --transition-speed: 0.3s;
}

.top-bar-wrapper {
    font-family: 'Arial', sans-serif;
    background-color: var(--background-color);
    border-bottom: 1px solid var(--border-color);
    padding: 0 20px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.top-bar-wrapper .logo {
    font-weight: bold;
    font-size: 1.2rem;
    color: var(--primary-color);
}

.top-bar-wrapper .menu {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.top-bar-wrapper .menu-item {
    margin-left: 20px;
}

.top-bar-wrapper .menu-link {
    color: var(--text-color);
    text-decoration: none;
    font-size: 0.9rem;
    padding: 5px 10px;
    border-radius: 4px;
    transition: background-color var(--transition-speed);
}

.top-bar-wrapper .menu-link:hover {
    background-color: var(--hover-color);
}

.top-bar-wrapper .menu-link.active {
    color: var(--primary-color);
    font-weight: bold;
}