.alerts-form {
    --primary-color: #3498db;
    --text-color: #333;
    --background-color: #f8f9fa;
    --input-background: #ffffff;
    --border-radius: 8px;
    --transition-speed: 0.3s;
}

.alerts-form {
    font-family: 'Arial', sans-serif;
    background-color: var(--background-color);
    color: var(--text-color);
    line-height: 1.6;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
}

.alerts-form .form-container {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: var(--border-radius);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
}

.alerts-form form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.alerts-form .form-group {
    display: flex;
    flex-direction: column;
}

.alerts-form label {
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.alerts-form input[type="number"],
.alerts-form input[type="text"] {
    padding: 0.75rem;
    border: 1px solid #e0e0e0;
    border-radius: var(--border-radius);
    font-size: 1rem;
    transition: border-color var(--transition-speed);
}

.alerts-form input[type="number"]:focus,
.alerts-form input[type="text"]:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
}

.alerts-form .checkbox-group {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.alerts-form .checkbox-group label {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    cursor: pointer;
}

.alerts-form input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #e0e0e0;
    border-radius: 4px;
    cursor: pointer;
    transition: all var(--transition-speed);
    position: relative;
    margin: 0;
    flex-shrink: 0;
    top: 3px;
    margin-right: 5px;
}

.alerts-form input[type="checkbox"]:checked {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

.alerts-form input[type="checkbox"]:checked::after {
    content: '\2714';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 14px;
}

.alerts-form button {
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    font-weight: 600;
    border-radius: var(--border-radius);
    cursor: pointer;
    transition: background-color var(--transition-speed);
}

.alerts-form button:hover {
    background-color: #2980b9;
}

.alerts-form .notification {
    margin-top: 1rem;
    padding: 0.75rem;
    border-radius: var(--border-radius);
    border: 1px solid #069441;
    background-color: #41e485;
    color: rgb(2, 93, 2);
    text-align: center;
    font-weight: 400;
    opacity: 0;
    transition: opacity var(--transition-speed);
}

.alerts-form .notification.show {
    opacity: 1;
}